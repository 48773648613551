<template>
    <div>
        <div class="parent">
            <div class="bold large" style="color: black;">欢迎回来</div>
            <!-- 选择登录方式-->
            <div v-if="showLogin">
                <div style="margin-top: 60px;">
                    <el-row>
                        <el-button @click="selectMethod('code')" class="titleFont button">
                            手机验证码登录
                        </el-button>
                    </el-row>
                </div>
                <div style="margin-top: 30px;">
                    <el-button @click="selectMethod('pass')" class=" titleFont button">密码登录
                    </el-button>
                </div>
            </div>
            <!-- 选择登录方式-->
            <!-- 登录-->
            <div v-else>
                <div class="register-item">
                    <label style="width: 100px;">手机号</label>
                    <div class="content">
                        <el-input placeholder="手机号" type="number" v-model="ruleForm.phone"
                                  autocomplete="off"></el-input>
                        <div v-if="!isValidPhone(ruleForm.phone)&&ruleForm.phone" class="error">请输入正确的手机号</div>
                    </div>
                </div>
                <!-- 验证码登录-->
                <div v-if="isCode" class="register-item">
                    <label style="width: 100px;">验证码</label>
                    <div class="content">
                        <el-input placeholder="请输入验证码" type="number" v-model="ruleForm.code"
                                  autocomplete="off"></el-input>
                        <el-button v-if="isCode" style="position: absolute;right: 5px;padding-left:0;padding-right:0;background-color: transparent;border: 0" :disabled="!isValidPhone(ruleForm.phone)" @click="getCode">{{
                            valiBtn
                            }}
                        </el-button>
                    </div>
                </div>
                <!-- 验证码登录-->
                <!-- 密码登录-->
                <div v-else class="register-item">
                    <label style="width: 100px;">请输入密码</label>
                    <div class="content">
                        <el-input placeholder="密码" type="password" v-model="ruleForm.pass"
                                  autocomplete="off"></el-input>
                    </div>
                </div>
                <!-- 密码登录-->
                <el-button class="button-login" @click="login(ruleForm,isCode,UserType.owner)">登录</el-button>
            </div>
            <!-- 登录-->

        </div>
    </div>

</template>

<script>

import {isValidPassword, isValidPhone, UserType} from "@/utils/common";
import vueCommon from "@/utils/VueMixin.js"
export default {
    name: "Login-user",
    computed: {
        UserType() {
            return UserType
        }
    },
    mixins:[vueCommon],
    data() {
        return {
            ruleForm: {
                phone: '',
                pass: '',
                code: '',
            },
            valiBtn: '获取验证码',
            disabled: false,
            showLogin: true,
            isCode: true,
            code: "",
        };
    },
    methods: {
        isValidPhone,
        selectMethod(str) {
            this.showLogin = false;
            this.isCode = str === "code";
        },
        //获取验证码 并只验证手机号 是否正确
        getCode() {
            if (!isValidPhone(this.ruleForm.phone)) {
                console.log('未通过')
            } else {
                this.tackBtn();   //验证码倒数60秒
                this.sendCode();
            }
        },
        tackBtn() {       //验证码倒数60秒
            let time = 60;
            let timer = setInterval(() => {
                if (time == 0) {
                    clearInterval(timer);
                    this.valiBtn = '获取验证码';
                    this.disabled = false;
                } else {
                    this.disabled = true;
                    this.valiBtn = time + '秒后重试';
                    time--;
                }
            }, 1000);
        },
        sendCode() {
            let url = `${this.$api.sendCode}?telephone=${this.ruleForm.phone}`;
            console.log(url)
            this.$http.post(url).then(res => {
                console.log(res)
                if (res.data.code === 0) {
                    this.code = res.data.sms_codes;
                }
            })
        },
    },


}
</script>

<style scoped>
.button-login {
    border-radius: 10px;
    margin-top: 30px;
    width: 230px;
    margin-left: 30px;
}

.button {
    border-radius: 15px;
    margin-bottom: 30px;
    width: 400px;
}

.titleFont {
    font-size: 30px;
}

.bold {
    font-weight: bold;
}

.large {
    font-size: 50px;
}

.parent {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/deep/ .el-form-itpx__label {
    color: #000000;
    font-weight: bold;
}


.parent {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/deep/ .el-form-itpx__error {
    color: black !important;
}

.register-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.register-item label {
    width: 100px;
    color: black;
    font-weight: bold;
}

.register-item .content {
    position: relative;
    font-size: 13px;
}

.register-item .content .error {
    position: absolute;
    font-size: 13px;
}


::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

::v-deep input[type=‘number’] {
    -moz-appearance: textfield !important;
}
</style>
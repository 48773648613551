<template>
    <div>
        <div class="parent">
            <div class="bold large" style="color: black;">欢迎注册</div>
            <div class="register-item">
                <label style="width: 100px;">手机号</label>
                <div class="content">
                    <el-input placeholder="手机号" type="number" v-model="ruleForm.phone" autocomplete="off"></el-input>
                    <div v-if="!isValidPhone(ruleForm.phone)&&ruleForm.phone" class="error">请输入正确的手机号</div>
                </div>
            </div>
            <div class="register-item">
                <label style="width: 100px;">验证码</label>
                <div class="content">
                    <el-input placeholder="请输入验证码" type="number" v-model="ruleForm.code"
                              autocomplete="off"></el-input>
                    <el-button style="position: absolute;right: 5px;padding-left:0;padding-right:0;background-color: transparent;border: 0" :disabled="!isValidPhone(this.ruleForm.phone)" @click="getCode">{{
                        valiBtn
                        }}
                    </el-button>
                </div>
            </div>
            <div class="register-item">
                <label style="width: 100px;">请设置密码</label>
                <div class="content">
                    <el-input placeholder="密码" type="password" v-model="ruleForm.pass"
                              autocomplete="off"></el-input>
                    <div v-if="!isValidPassword(ruleForm.pass)&&ruleForm.pass" class="error">6-12位数字字母组合，区分大小写</div>
                </div>
            </div>
            <div class="register-item">
                <label style="width: 100px;">产品编码</label>
                <div class="content">
                    <el-input placeholder="可登录后绑定" type="text" v-model="ruleForm.No"
                              autocomplete="off"></el-input>
                </div>
            </div>

            <el-button class="button" :disabled="allPass" @click="register">注册</el-button>
        </div>
    </div>

</template>

<script>

import {isValidPassword, isValidPhone} from "@/utils/common";
import fa from "element-ui/src/locale/lang/fa";
import {createLogger} from "vuex";

export default {
    name: "Register",
    data() {
        return {
            ruleForm: {
                phone: '',
                pass: '',
                code: '',
                No: '',

            },
            valiBtn: '获取验证码',
            disabled: true,
            code: "",
        };
    },
    computed: {
        allPass() {
            if (this.isValidPhone(this.ruleForm.phone) && this.isValidPassword(this.ruleForm.pass) && this.ruleForm.code !== '') {
                return false;
            }
            return true;
        },
    },
    methods: {
        isValidPhone,
        isValidPassword,
        //获取验证码 并只验证手机号 是否正确
        getCode() {
            if (!isValidPhone(this.ruleForm.phone)) {
                console.log('未通过')
            } else {
                this.tackBtn();   //验证码倒数60秒
                this.sendCode();
            }
        },
        tackBtn() {       //验证码倒数60秒
            let time = 60;
            let timer = setInterval(() => {
                if (time == 0) {
                    clearInterval(timer);
                    this.valiBtn = '获取验证码';
                    this.disabled = false;
                } else {
                    this.disabled = true;
                    this.valiBtn = time + '秒后重试';
                    time--;
                }
            }, 1000);
        },
        sendCode() {
            let url = `${this.$api.sendCode}?telephone=${this.ruleForm.phone}`;
            console.log(url)
            this.$http.post(url).then(res => {
                console.log(res)
                if (res.data.code === 0) {
                    this.code = res.data.sms_codes;
                }
            })
        },
        register() {
            let url = undefined;
            if (this.ruleForm.No != '') {
                url = `${this.$api.register}/?telephone=${this.ruleForm.phone}&smsCodes=${this.ruleForm.code}&password=${this.ruleForm.pass}&deviceCode=${this.ruleForm.No}`;
            }
            url = `${this.$api.register}/?telephone=${this.ruleForm.phone}&smsCodes=${this.ruleForm.code}&password=${this.ruleForm.pass}`;
            this.$http.post(url).then(res => {
                console.log(res);
                if (res.data.code === 0) {
                    this.$alert('注册成功！', '成功', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                }else{
                    this.$alert(res.data.message, '失败', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                }
            });
        }
    }

}
</script>

<style scoped>

.button {
    border-radius: 10px;
    margin-top: 30px;
    width: 230px;
    margin-left: 30px;
}

/deep/ .el-form-itpx__label {
    color: #000000;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.large {
    font-size: 50px;
}

.parent {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    /*padding: 20px;*/
}

/deep/ .el-form-itpx__error {
    color: black !important;
}

.register-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.register-item label {
    width: 100px;
    color: black;
    font-weight: bold;
}

.register-item .content {
    position: relative;
    font-size: 13px;
}

.register-item .content .error {
    position: absolute;
    font-size: 13px;
}


::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

::v-deep input[type=‘number’] {
    -moz-appearance: textfield !important;
}


</style>
<template>
    <div>
        <div class="login-parent">
            <img class="logo" src="../assets/img/login-logo.png" alt=""/>
            <div class="login-back" v-if="showComponents[currentKey] !== 1" @click="loginBack">返回</div>
            <Login @login-click="intoRegister" v-if="showComponents[currentKey] === 1" class="position"></Login>
            <Register v-else-if="showComponents[currentKey] === 2" class="position"></Register>
            <Login-user v-else-if="showComponents[currentKey] === 3" class="position"></Login-user>
            <Login-admin v-else-if="showComponents[currentKey] === 4" class="position"></Login-admin>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import LoginUser from "@/components/Login-user.vue";
import LoginAdmin from "@/components/Login-admin.vue";

export default {
    name: 'LoginMain',
    components: {
        LoginUser,
        Login,
        Register,
        LoginAdmin
    },
    data() {
        return {
            currentKey: "showLogin",
            showComponents: {
                "showLogin": 1,
                "showRegister": 2,
                "showLoginUser": 3,
                "showLoginAdmin": 4,
            },
            before: "showLogin",
        }
    },
    mounted() {
        // 监听 popstate 事件
        window.addEventListener('popstate', this.onPopstate);
        this.currentKey = this.$route.query.key;
        if (!this.currentKey) {
            this.currentKey = "showLogin";
        }
    },
    methods: {
        intoRegister(key) {
            this.before = this.currentKey;
            this.$router.replace(`?key=${key}`)
            this.currentKey = key;
        },
        loginBack() {
            this.intoRegister("showLogin")
        },
        onPopstate(event) {
            this.currentKey = this.before;
            this.$router.replace(`?key=${this.before}`)
        }
    },
    created() {
        this.$bus.$on('login-click', this.intoRegister)
    },
    destroyed() {
        // 移除 popstate 事件监听
        window.removeEventListener('popstate', this.onPopstate);
        // 监听 popstate 事件
        window.addEventListener('popstate', this.onPopstate);
        this.$bus.$off('login-click', this.intoRegister)
    }

}
</script>

<style lang="less">
.login-parent {
  width: 80vw;
  height: 98vh;
  position: relative;
  background-image: url("../assets/background.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.position {
  position: absolute;
  top: 300px;
  right: 160px;
}

.login-back {
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #fff;
  display: inline-flex;
  width: 60px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.login-back:hover {
  color: red;
}

.logo {
  position: absolute;
  width: 300px;
  height: 171px;
  z-index: 2;
  top: 50px;
  right: 130px;
}

</style>
